import { get3DModelData } from '@mpx-sdk/helpers/assets';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { defaultThumbnail } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	inAppBrowserAtom,
	singleAssetViewAtom,
	singleAssetViewMetadataAtom,
	userAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import WarningSnackbar from '@mpx-sdk/ui/components/admin/WarningSnackbar';
import ThumbnailButtons from '@mpx-sdk/ui/components/single-asset-view/components/ThumbnailButtons';
import { Box, MenuItem, Select, Slider, Stack, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { isEmpty, isEqual } from 'lodash';
import Image from 'next/image';
import { ReactElement, useEffect, useRef, useState } from 'react';

if (typeof window !== 'undefined') {
	import('@google/model-viewer');
}

interface ModelViewerProps {
	gmvData: any;
	onLoad?: () => void | Promise<void> | any;
}

export default function ModelViewer({ gmvData, onLoad }: ModelViewerProps): ReactElement | null {
	const [displayControls, setDisplayControls] = useState(false);
	const [exposure, setExposure] = useState(gmvData?.exposure ?? 1);
	const [metallicRoughnessDisplay, setMetallicRoughnessDisplay] = useState<any>(null);
	const [metallicRoughnessValues, setMetallicRoughnessValues] = useState<any>(null);
	const [shadowIntensity, setShadowIntensity] = useState(gmvData?.['shadow-intensity'] ?? 1);
	const [shadowSoftness, setShadowSoftness] = useState(gmvData?.['shadow-softness'] ?? 0.5);
	const [showAdminWarning, setShowAdminWarning] = useState(false);
	const adminView = useAtomValue(adminFeaturesAtom);
	const cachedProjectDataId: any = useRef(null);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const projectData = useAtomValue(singleAssetViewAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const setSAVMetaData = useSetAtom(singleAssetViewMetadataAtom);

	// iOS checker:
	const isIOS = /(iPhone|iPod)/.test(navigator.userAgent);
	const iOSVersionMatch = navigator.userAgent.match(/OS (\d+)_(\d+)/);
	const iOSVersion = iOSVersionMatch ? parseFloat(iOSVersionMatch[1]) : undefined;
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	function updateModelViewer(what: string, value: number | number[], index = 0) {
		const modelViewer = document.querySelector('model-viewer') as any;

		if (modelViewer) {
			if (['metallic', 'roughness'].includes(what)) {
				const material = modelViewer?.model?.materials?.[index];
				if (material) {
					if (what === 'metallic') {
						material.pbrMetallicRoughness?.setMetallicFactor(value);
					} else {
						material.pbrMetallicRoughness?.setRoughnessFactor(value);
					}
				}
			} else {
				modelViewer[what] = value;
			}
		}
	}

	async function getMetallicRoughnessValues(materials: any[]) {
		const tempValues: any = {};

		// Ensure that the materials are loaded
		if (materials) {
			await Promise.all(
				materials.map(
					async (
						material: {
							ensureLoaded?: any;
							pbrMetallicRoughness?: any;
						},
						index: string | number,
					) => {
						// Ensure the materials are loaded uses ensureLoaded()
						await material.ensureLoaded();

						const { pbrMetallicRoughness } = material;

						if (pbrMetallicRoughness) {
							const metallicFactor = await pbrMetallicRoughness.metallicFactor;
							const roughnessFactor = await pbrMetallicRoughness.roughnessFactor;

							tempValues[index] = {
								metallic: metallicFactor,
								roughness: roughnessFactor,
							};
						}
					},
				),
			);
		}

		setMetallicRoughnessValues(tempValues);

		// Grab first material name
		if (!isEmpty(tempValues)) {
			setMetallicRoughnessDisplay(Object.keys(tempValues)[0]);
		}
	}

	async function adjustMetalRough(iterativeCall = 0, iterativeMax = 20) {
		/** Currently loaded model */
		const model = document.querySelector('model-viewer');

		/** Model's materials */
		const material = model?.model?.materials;

		// Set textures
		if (iterativeCall < iterativeMax) {
			if (material && model) {
				if (!metallicRoughnessValues) {
					await getMetallicRoughnessValues(material);
				}
			} else {
				setTimeout(() => {
					adjustMetalRough(iterativeCall + 1);
				}, 500);
			}
		}
	}

	function process3DModelData(data: any) {
		if (!isEmpty(projectData) && data?.info && isEqual(data?.id, cachedProjectDataId?.current)) {
			const newProjectData: PublicAsset = {
				...projectData,
				metadata: {
					...projectData?.metadata,
					num_verts: data.info.totalVertexCount ?? null,
					num_triangles: data.info.totalTriangleCount ?? null,
				},
			};

			setSAVMetaData(newProjectData.metadata);
		}
	}

	async function grab3DModelData() {
		if (projectData) {
			const data = await get3DModelData(gmvData.src, {
				projectData,
				type: 'online',
			});

			process3DModelData(data);
		}
	}

	useEffect(() => {
		if (projectData && projectData.id !== cachedProjectDataId?.current) {
			grab3DModelData();

			cachedProjectDataId.current = projectData.id;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gmvData, projectData]);

	useEffect(() => {
		adjustMetalRough();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		gmvData?.src &&
		Boolean(WebGL2RenderingContext || WebGLRenderingContext) &&
		(!isIOS || !isSafari || !iOSVersion || (isIOS && isSafari && iOSVersion && iOSVersion >= 14))
	) {
		return (
			<>
				{currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id && (
					<WarningSnackbar
						isOpen={showAdminWarning}
						message="You are not the owner of this project. Saving new thumbnail will override the user's original thumbnail."
						onClose={() => setShowAdminWarning(false)}
					/>
				)}

				<model-viewer load={onLoad} {...gmvData}>
					<ThumbnailButtons
						displayControls={displayControls}
						setDisplayControls={setDisplayControls}
						setShowAdminWarning={setShowAdminWarning}
						type='gmv'
					/>
				</model-viewer>

				{/* Controls for the Google Model Viewer */}
				{!inApp && responsiveView && (
					<Stack
						alignItems='flex-start'
						aria-label='Google Model Viewer controls'
						className='google-model-viewer-controls'
						direction='column'
						justifyContent='flex-start'
						spacing={2}
						sx={{
							display: displayControls ? 'block' : 'none',
							width: '100%',
						}}
					>
						{/* Sliders */}
						<div>
							{`Exposure: ${exposure} `}
							<Slider
								marks={[
									{
										label: '0',
										value: 0,
									},
									{
										label: '2',
										value: 2,
									},
								]}
								max={2}
								min={0}
								onChange={(_e, newValue) => {
									setExposure(newValue as number);
									updateModelViewer('exposure', newValue);
								}}
								step={0.01}
								value={exposure}
							/>
						</div>

						<div>
							{`Shadow intensity: ${shadowIntensity} `}
							<Slider
								marks={[
									{
										label: '0',
										value: 0,
									},
									{
										label: '2',
										value: 2,
									},
								]}
								max={2}
								min={0}
								onChange={(_e, newValue) => {
									setShadowIntensity(newValue as number);
									updateModelViewer('shadowIntensity', newValue);
								}}
								step={0.01}
								value={shadowIntensity}
							/>
						</div>

						<div>
							{`Shadow softness: ${shadowSoftness} `}
							<Slider
								marks={[
									{
										label: '0',
										value: 0,
									},
									{
										label: '1',
										value: 1,
									},
								]}
								max={1}
								min={0}
								onChange={(_e, newValue) => {
									setShadowSoftness(newValue as number);
									updateModelViewer('shadowSoftness', newValue);
								}}
								step={0.01}
								value={shadowSoftness}
							/>
						</div>

						{metallicRoughnessDisplay && metallicRoughnessValues && (
							// Select material, then slide for both metallic and roughness
							<Stack
								alignItems='flex-start'
								direction='column'
								justifyContent='flex-start'
								spacing={2}
								sx={{
									width: '100%',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										width: '100%',
									}}
								>
									<Typography sx={{ marginRight: '8px' }}>Material:</Typography>
									<Select
										id='material'
										labelId='material'
										onChange={(e) => {
											setMetallicRoughnessDisplay(e.target.value as string);
										}}
										sx={{
											flex: 1,
											border: (theme) => `1px solid ${theme.palette.background.paper}`,
										}}
										title='Change material'
										value={metallicRoughnessDisplay}
									>
										{Object.keys(metallicRoughnessValues).map((material) => (
											<MenuItem key={material} value={material}>
												{material}
											</MenuItem>
										))}
									</Select>
								</Box>

								<Box
									sx={{
										width: '100%',
									}}
								>
									{`Roughness: ${metallicRoughnessValues[metallicRoughnessDisplay]?.roughness} `}
									<Slider
										marks={[
											{
												label: '0',
												value: 0,
											},
											{
												label: '1',
												value: 1,
											},
										]}
										max={1}
										min={0}
										onChange={(_e, newValue) => {
											// Update values
											const tempNewValues = {
												...metallicRoughnessValues,
											};
											tempNewValues[metallicRoughnessDisplay].roughness = newValue as number;
											setMetallicRoughnessValues(tempNewValues);

											updateModelViewer(
												'roughness',
												newValue,
												metallicRoughnessValues[metallicRoughnessDisplay].index,
											);
										}}
										step={0.01}
										value={metallicRoughnessValues[metallicRoughnessDisplay].roughness}
									/>
								</Box>

								<Box
									sx={{
										width: '100%',
									}}
								>
									{`Metallic: ${metallicRoughnessValues[metallicRoughnessDisplay].metallic} `}
									<Slider
										marks={[
											{
												label: '0',
												value: 0,
											},
											{
												label: '1',
												value: 1,
											},
										]}
										max={1}
										min={0}
										onChange={(_e, newValue) => {
											// Update values
											const tempNewValues = {
												...metallicRoughnessValues,
											};
											tempNewValues[metallicRoughnessDisplay].metallic = newValue as number;
											setMetallicRoughnessValues(tempNewValues);

											updateModelViewer(
												'metallic',
												newValue,
												metallicRoughnessValues[metallicRoughnessDisplay].index,
											);
										}}
										step={0.01}
										value={metallicRoughnessValues[metallicRoughnessDisplay].metallic}
									/>
								</Box>
							</Stack>
						)}
					</Stack>
				)}
			</>
		);
	}

	return (
		<Image
			alt='No model found or issue loading model'
			data-testid='sav-thumbnail'
			fill
			src={gmvData?.poster ?? defaultThumbnail}
			style={{
				height: '100%',
				objectFit: 'cover',
			}}
		/>
	);
}
